import React from "react";

function Tools() {
  return (
    <div className="container">
      <div className="row mt-3 justify-content-center  mb-3 ">
        <div className="col-md-10   p-3">
          <div className="row  mt-3 mb-3">
            <div className="about-heading">Research a Keyword</div>
            <div className="col-md-10">
              <input
                className="input_box"
                type="type"
                placeholder="Search for keyword for actionable insights"
              />
            </div>
            <div className="col-md-2 d-flex justify-content-end">
              <button className="btn btn-primary search_icon" type="submit">
                Search
              </button>
            </div>
          </div>

          <div className="desc">Learn about competitors</div>

          <table class="table table-bordered mt-3">
            <thead>
              <tr>
                <th style={{ width: "310px" }} className="t_head" scope="col">
                  Top Products
                </th>
                <th className="t_head" scope="col">
                  Monthly Sales (Last 30 Days)
                </th>
                <th className="t_head" scope="col">
                  Monthly Revenue (Last 30 Days)
                </th>
                <th className="t_head" scope="col">
                  Reviews & Ratings
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th scope="row">
                  <div className="row">
                    <div className="col-md-1">
                      <img
                        src="./images/phone.webp"
                        alt="loading"
                        className="t-img"
                      />
                    </div>
                    <div
                      className="col-md-11  d-flex"
                      style={{ alignItems: "center" }}
                    >
                      <div className="t-hyperlink">B09G9BL5CP</div>
                      <div className="t_desc">Apple iPhone 13</div>
                    </div>
                  </div>
                </th>
                <td className="t_desc">7,461</td>
                <td className="t_desc">₹369,312,039</td>
                <td className="t_desc1">
                  25,313
                  <div style={{ color: "gold" }}>
                    <i className="fa-solid fa-star s_tar"></i>
                    <i className="fa-solid fa-star s_tar"></i>
                    <i className="fa-solid fa-star s_tar"></i>
                    <i className="fa-solid fa-star s_tar"></i>
                    <i className="fa-solid fa-star s_tar"></i>
                  </div>
                  4.6
                </td>
              </tr>

              <tr>
                <th scope="row">
                  <div className="row">
                    <div className="col-md-1">
                      <img
                        src="./images/phone.webp"
                        alt="loading"
                        className="t-img"
                      />
                    </div>
                    <div
                      className="col-md-11  d-flex"
                      style={{ alignItems: "center" }}
                    >
                      <div className="t-hyperlink">B09G9BL5CP</div>
                      <div className="t_desc">Apple iPhone 13</div>
                    </div>
                  </div>
                </th>
                <td className="t_desc">7,461</td>
                <td className="t_desc">₹369,312,039</td>
                <td className="t_desc1">
                  25,313
                  <div style={{ color: "gold" }}>
                    <i className="fa-solid fa-star s_tar"></i>
                    <i className="fa-solid fa-star s_tar"></i>
                    <i className="fa-solid fa-star s_tar"></i>
                    <i className="fa-solid fa-star s_tar"></i>
                    <i className="fa-solid fa-star s_tar"></i>
                  </div>
                  4.6
                </td>
              </tr>

              <tr>
                <th scope="row">
                  <div className="row">
                    <div className="col-md-1">
                      <img
                        src="./images/phone.webp"
                        alt="loading"
                        className="t-img"
                      />
                    </div>
                    <div
                      className="col-md-11  d-flex"
                      style={{ alignItems: "center" }}
                    >
                      <div className="t-hyperlink">B09G9BL5CP</div>
                      <div className="t_desc">Apple iPhone 13</div>
                    </div>
                  </div>
                </th>
                <td className="t_desc">7,461</td>
                <td className="t_desc">₹369,312,039</td>
                <td className="t_desc1">
                  25,313
                  <div style={{ color: "gold" }}>
                    <i className="fa-solid fa-star s_tar"></i>
                    <i className="fa-solid fa-star s_tar"></i>
                    <i className="fa-solid fa-star s_tar"></i>
                    <i className="fa-solid fa-star s_tar"></i>
                    <i className="fa-solid fa-star s_tar"></i>
                  </div>
                  4.6
                </td>
              </tr>

              <tr>
                <th scope="row">
                  <div className="row">
                    <div className="col-md-1">
                      <img
                        src="./images/phone.webp"
                        alt="loading"
                        className="t-img"
                      />
                    </div>
                    <div
                      className="col-md-11  d-flex"
                      style={{ alignItems: "center" }}
                    >
                      <div className="t-hyperlink">B09G9BL5CP</div>
                      <div className="t_desc">Apple iPhone 13</div>
                    </div>
                  </div>
                </th>
                <td className="t_desc">7,461</td>
                <td className="t_desc">₹369,312,039</td>
                <td className="t_desc1">
                  25,313
                  <div style={{ color: "gold" }}>
                    <i className="fa-solid fa-star s_tar"></i>
                    <i className="fa-solid fa-star s_tar"></i>
                    <i className="fa-solid fa-star s_tar"></i>
                    <i className="fa-solid fa-star s_tar"></i>
                    <i className="fa-solid fa-star s_tar"></i>
                  </div>
                  4.6
                </td>
              </tr>

              <tr>
                <th scope="row">
                  <div className="row">
                    <div className="col-md-1">
                      <img
                        src="./images/phone.webp"
                        alt="loading"
                        className="t-img"
                      />
                    </div>
                    <div
                      className="col-md-11  d-flex"
                      style={{ alignItems: "center" }}
                    >
                      <div className="t-hyperlink">B09G9BL5CP</div>
                      <div className="t_desc">Apple iPhone 13</div>
                    </div>
                  </div>
                </th>
                <td className="t_desc">7,461</td>
                <td className="t_desc">₹369,312,039</td>
                <td className="t_desc1">
                  25,313
                  <div style={{ color: "gold" }}>
                    <i className="fa-solid fa-star s_tar"></i>
                    <i className="fa-solid fa-star s_tar"></i>
                    <i className="fa-solid fa-star s_tar"></i>
                    <i className="fa-solid fa-star s_tar"></i>
                    <i className="fa-solid fa-star s_tar"></i>
                  </div>
                  4.6
                </td>
              </tr>

              <tr>
                <th scope="row">
                  <div className="row">
                    <div className="col-md-1">
                      <img
                        src="./images/phone.webp"
                        alt="loading"
                        className="t-img"
                      />
                    </div>
                    <div
                      className="col-md-11  d-flex"
                      style={{ alignItems: "center" }}
                    >
                      <div className="t-hyperlink">B09G9BL5CP</div>
                      <div className="t_desc">Apple iPhone 13</div>
                    </div>
                  </div>
                </th>
                <td className="t_desc">7,461</td>
                <td className="t_desc">₹369,312,039</td>
                <td className="t_desc1">
                  25,313
                  <div style={{ color: "gold" }}>
                    <i className="fa-solid fa-star s_tar"></i>
                    <i className="fa-solid fa-star s_tar"></i>
                    <i className="fa-solid fa-star s_tar"></i>
                    <i className="fa-solid fa-star s_tar"></i>
                    <i className="fa-solid fa-star s_tar"></i>
                  </div>
                  4.6
                </td>
              </tr>

              <tr>
                <th scope="row">
                  <div className="row">
                    <div className="col-md-1">
                      <img
                        src="./images/phone.webp"
                        alt="loading"
                        className="t-img"
                      />
                    </div>
                    <div
                      className="col-md-11  d-flex"
                      style={{ alignItems: "center" }}
                    >
                      <div className="t-hyperlink">B09G9BL5CP</div>
                      <div className="t_desc">Apple iPhone 13</div>
                    </div>
                  </div>
                </th>
                <td className="t_desc">7,461</td>
                <td className="t_desc">₹369,312,039</td>
                <td className="t_desc1">
                  25,313
                  <div style={{ color: "gold" }}>
                    <i className="fa-solid fa-star s_tar"></i>
                    <i className="fa-solid fa-star s_tar"></i>
                    <i className="fa-solid fa-star s_tar"></i>
                    <i className="fa-solid fa-star s_tar"></i>
                    <i className="fa-solid fa-star s_tar"></i>
                  </div>
                  4.6
                </td>
              </tr>

              <tr>
                <th scope="row">
                  <div className="row">
                    <div className="col-md-1">
                      <img
                        src="./images/phone.webp"
                        alt="loading"
                        className="t-img"
                      />
                    </div>
                    <div
                      className="col-md-11  d-flex"
                      style={{ alignItems: "center" }}
                    >
                      <div className="t-hyperlink">B09G9BL5CP</div>
                      <div className="t_desc">Apple iPhone 13</div>
                    </div>
                  </div>
                </th>
                <td className="t_desc">7,461</td>
                <td className="t_desc">₹369,312,039</td>
                <td className="t_desc1">
                  25,313
                  <div style={{ color: "gold" }}>
                    <i className="fa-solid fa-star s_tar"></i>
                    <i className="fa-solid fa-star s_tar"></i>
                    <i className="fa-solid fa-star s_tar"></i>
                    <i className="fa-solid fa-star s_tar"></i>
                    <i className="fa-solid fa-star s_tar"></i>
                  </div>
                  4.6
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default Tools;
